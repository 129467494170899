import styled, { css } from 'styled-components';
import { Base } from '~/components/layout';

const ProcessMainCaption = styled(Base)`
  ${({ theme }) => css`
    position: absolute;
    top: calc(100% + ${theme.spacers[4]}px);
    left: 50%;
    width: 110px;
    margin-bottom: ${theme.spacers[4]}px;
    line-height: ${theme.lineHeight.sm};
    font-size: ${theme.fontSize.xs}px;
    color: ${theme.colors.gray600};
    transform: translateX(-50%);
    transition: top 0.6s cubic-bezier(0.65, -0.3, 0, 1.2);
  `}
`;

export default ProcessMainCaption;
