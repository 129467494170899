import styled, { css } from 'styled-components';
import { Base } from '~/components/layout';

const ProcessContainer = styled(Base)`
  ${() => css`
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
  `}
`;

export default ProcessContainer;
