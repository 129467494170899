import { BaseImg } from '~/components/layout/Base';
import ProcessItemCaption from './ProcessItemCaption';
import styled, { css } from 'styled-components';

type ProcessItemIconProps = {
  order: 1 | 2 | 3 | 4;
  isActive?: boolean;
};

const ProcessItemIcon = styled(BaseImg)<ProcessItemIconProps>`
  ${({ order, isActive, theme }) => css`
    width: 100%;
    height: 100%;
    opacity: ${isActive ? 1 : 0};
    transform: scale(${isActive ? 1 : 0.5});
    transform-origin: center center;
    transition: all 0.6s cubic-bezier(0.65, -0.3, 0, 1.2);
    transition-delay: ${isActive && order && `${order * 0.6}s`};

    & + ${ProcessItemCaption} {
      top: calc(100% + ${theme.spacers[4]}px);
      bottom: auto;
    }
  `}
`;

export default ProcessItemIcon;
