import React, { useContext, useEffect, useRef, useState } from 'react';
import Carousel from 'react-slick';
import { Block, Col, Container, FlexRow, Row } from '../components/layout';
import { Button, Form, Input } from '../components/form';
import { Card, CardBody, CardStack, CardTop } from '../components/card';
import { CardSlider, Slider } from '../components/vendor';
import { ContactContext } from '../layouts/FrontLayout';
import { Divider, Image, Shape, Scrollable } from '../components/ui';
import { FrontLayout } from '~/layouts/FrontLayout';
import { Heading, Text } from '../components/type';
import { ListIcon, ListIconItem } from '../components/list';
import { Nav } from '../components/nav';
import { ProcessAnimations, ProcessList, ProcessListItem } from '../components/process';
import { TestMatchForm } from '../containers';
import { TestMatchModal } from '../modals';
import FileDone from '~/components/icons/IconFileDone';
import Group from '~/components/icons/IconGroup';
import Head from 'next/head';
import Link from 'next/link';
import MailUrgent from '~/components/icons/IconMailUrgent';
import Monitor from '~/components/icons/IconMonitor';
import { ProcessAnimationInnerStep } from '~/components/process/ProcessAnimations';

export function getStaticProps() {
  return {
    props: {},
  };
}

function Homepage() {
  const currentYear = new Date().getFullYear();
  const contactFormContext = useContext(ContactContext);
  /**
   * Welcome
   */

  const welcome = (
    <Block as="section" utils={{ pt: 14 }} desktop={{ pt: 16 }}>
      <Container>
        <Row utils={{ justifyContent: 'center' }}>
          <Col span="100%" desktop={{ span: '65%' }}>
            <Heading as="h6" utils={{ fontSize: 'xs', textAlign: 'center', textTransform: 'uppercase', color: 'primary', mb: 6 }}>
              <Text as="span" desktop={{ display: 'none' }}>
                CRE
              </Text>
              <Text as="span" mobile={{ display: 'none' }}>
                Commercial real estate
              </Text>{' '}
              in the cloud
            </Heading>
            <Heading as="h1" utils={{ textAlign: 'center', fontSize: '8xl', fontWeight: 'bolder', mb: 6 }}>
              Fund your commercial real estate deal
            </Heading>
            <Text utils={{ textAlign: 'center', fontSize: '2xl', color: 'gray800', mb: 9 }}>
              Our platform combines an expert capital markets team and technology to build a full deal package and match it with our ideal lending sources.
            </Text>
            <Form
              utils={{ maxWidth: '420px', mx: 'auto', mb: 8 }}
              desktop={{ mb: 13 }}
              onSubmit={(e) => {
                e.preventDefault();
                const email = contactFormContext.contactForm.email;
                if (email.length !== 0) {
                  contactFormContext.setIsContactModalOpen(true);
                  (e.target as HTMLFormElement).reset();
                }
              }}
            >
              <FlexRow>
                <Input
                  type="email"
                  name="email"
                  placeholder="Email"
                  utils={{ boxShadow: 2, mr: 6 }}
                  required
                  onChange={(e) => {
                    const email = e.target.value;
                    contactFormContext.setContactFormEmail(email);
                  }}
                />
                <Button variant="primary" utils={{ boxShadow: 2 }}>
                  Get Started
                </Button>
              </FlexRow>
            </Form>
          </Col>
        </Row>
        <Row utils={{ alignItems: 'center' }} gutter={0}>
          <Col span="100%" desktop={{ span: '90%' }}>
            <picture>
              <source srcSet="/img/screenshots/screenshot-1@2x.avif 2x, /img/screenshots/screenshot-1.avif" type="image/avif" />
              <source srcSet="/img/screenshots/screenshot-1@2x.webp 2x, /img/screenshots/screenshot-1.webp" type="image/webp" />
              <Image
                src="/img/screenshots/screenshot-1.jpg"
                srcSet="/img/screenshots/screenshot-1@2x.jpg 2x"
                alt="..."
                width="990"
                height="784"
                utils={{ borderRadius: 'base', boxShadow: 6 }}
                isFluid
              />
            </picture>
          </Col>
          <Col span="30%" mobile={{ display: 'none' }} style={{ marginLeft: '-20%' }}>
            <picture>
              <source srcSet="/img/screenshots/screenshot-2@2x.avif 2x, /img/screenshots/screenshot-2.avif" type="image/avif" />
              <source srcSet="/img/screenshots/screenshot-2@2x.webp 2x, /img/screenshots/screenshot-2.webp" type="image/webp" />
              <Image
                src="/img/screenshots/screenshot-2.jpg"
                srcSet="/img/screenshots/screenshot-2@2x.jpg 2x"
                utils={{ position: 'relative', borderRadius: 'base', boxShadow: 6 }}
                width="330"
                height="493"
                alt="..."
                style={{ zIndex: 1 }}
                isFluid
                loading="lazy"
              />
            </picture>
          </Col>
        </Row>
      </Container>
    </Block>
  );

  /**
   * Process
   */

  const [processAutoplay, setProcessAutoplay] = useState(false);
  const [processStep, setProcessStep] = useState(0);
  const processTimeoutRef = useRef<NodeJS.Timeout>();
  const processRef = useRef<HTMLDivElement>(null);

  function processAutoadvance() {
    setProcessStep(processStep < 3 ? processStep + 1 : 0);
  }

  function handleProcessClick(step: number) {
    setProcessStep(step);
    setProcessAutoplay(false);
    clearTimeout(processTimeoutRef.current);
  }

  useEffect(function () {
    if (processAutoplay) {
      processTimeoutRef.current = setTimeout(processAutoadvance, 5000);
      return () => clearTimeout(processTimeoutRef.current);
    }
  });

  useEffect(function () {
    if ('IntersectionObserver' in window) {
      let targetNode: HTMLDivElement;
      const intersectionObserver = new IntersectionObserver(function (entries) {
        if (processRef.current) {
          targetNode = processRef.current;
          setProcessAutoplay(entries[0].intersectionRatio > 0);

          if (entries[0].intersectionRatio <= 0) {
            clearTimeout(processTimeoutRef.current);
          }
        }
      });

      intersectionObserver.observe(processRef.current!);
      return () => {
        if (targetNode) {
          intersectionObserver.unobserve(targetNode);
        }
      };
    }
  }, []);

  const process = (
    <>
      <Block utils={{ position: 'relative' }}>
        <Shape indent={10} slant={8} desktop={{ indent: 14, slant: 14 }} />
      </Block>
      <Block as="section" utils={{ position: 'relative', pb: 10, bgColor: 'black' }} desktop={{ pb: 14 }}>
        <Container>
          <Row>
            <Col span="100%" desktop={{ span: '50%' }}>
              <Heading as="h6" utils={{ fontSize: 'xs', textTransform: 'uppercase', color: 'primary', mb: 6 }}>
                Our process
              </Heading>
              <Heading as="h1" utils={{ fontSize: '6xl', fontWeight: 'bolder', color: 'white', mb: 6 }}>
                A fully integrated suite of tools and automations.
              </Heading>
            </Col>
          </Row>
          <Row utils={{ mb: 8 }}>
            <Col span="100%" desktop={{ span: '50%' }}>
              <Text utils={{ fontSize: 'lg', color: 'gray700', mb: 6 }}>
                Our platform brings together everything that’s required to fund a commercial real estate deal, from initial discovery through a fully funded
                close. WelcomeLend’s platform enables our capital markets team to streamline this entire process.
              </Text>
            </Col>
            <Col span="100%" desktop={{ span: '50%' }}>
              <Text utils={{ fontSize: 'lg', color: 'gray700', mb: 6 }}>
                Collecting documents and generating an online Offering Memorandum becomes simple and efficient. Once the basic deal info is shared, our
                algorithms match your deal with banks and private lending sources from our ever growing database.
              </Text>
            </Col>
          </Row>
          <Row utils={{ alignItems: 'center' }} ref={processRef}>
            <Col span="100%" desktop={{ span: '40%' }}>
              <ProcessList autoplay={processAutoplay}>
                <ProcessListItem icon={<MailUrgent />} isActive={processStep === 0} onClick={() => handleProcessClick(0)}>
                  <Heading as="h6" utils={{ fontSize: 'lg', color: 'white', mb: 3 }}>
                    1. Deal Flow
                  </Heading>
                  <Text utils={{ color: 'gray700', mb: 8 }} desktop={{ mb: 0 }}>
                    Automations gather over 100,000 deals annually, helping us understand the capital markets and active deals.
                  </Text>
                  <ProcessAnimations step={0} desktop={{ display: 'none' }} />
                </ProcessListItem>
                <ProcessListItem icon={<FileDone />} isActive={processStep === 1} onClick={() => handleProcessClick(1)}>
                  <Heading as="h6" utils={{ fontSize: 'lg', color: 'white', mb: 3 }}>
                    2. Document Collection
                  </Heading>
                  <Text utils={{ color: 'gray700', mb: 8 }} desktop={{ mb: 0 }}>
                    Collecting and approving documents is simple with inline document previews, reviewing tools, and automated reminders.
                  </Text>
                  <ProcessAnimations step={1} desktop={{ display: 'none' }} />
                </ProcessListItem>
                <ProcessListItem icon={<Monitor />} isActive={processStep === 2} onClick={() => handleProcessClick(2)}>
                  <Heading as="h6" utils={{ fontSize: 'lg', color: 'white', mb: 3 }}>
                    3. Offering Memorandum
                  </Heading>
                  <Text utils={{ color: 'gray700', mb: 8 }} desktop={{ mb: 0 }}>
                    Building a beautiful, online OM is effortless, including market insights, an explorable map, and a team profile.
                  </Text>
                  <ProcessAnimations step={2} desktop={{ display: 'none' }} />
                </ProcessListItem>
                <ProcessListItem icon={<Group />} isActive={processStep === 3} onClick={() => handleProcessClick(3)}>
                  <Heading as="h6" utils={{ fontSize: 'lg', color: 'white', mb: 3 }}>
                    4. Lender Matching
                  </Heading>
                  <Text utils={{ color: 'gray700', mb: 8 }} desktop={{ mb: 0 }}>
                    Using in-depth program data, we identify the best capital source and structure based off the specifics of your deal.
                  </Text>
                  <ProcessAnimations step={3} desktop={{ display: 'none' }} />
                </ProcessListItem>
              </ProcessList>
            </Col>
            <Col span="100%" desktop={{ span: '60%' }}>
              <ProcessAnimations step={processStep as ProcessAnimationInnerStep} mobile={{ display: 'none' }} />
            </Col>
          </Row>
        </Container>
      </Block>
      <Block utils={{ position: 'relative' }}>
        <Shape horizontal="left" vertical="bottom" slant={8} desktop={{ slant: 14 }} />
      </Block>
    </>
  );

  /*
   * Matching
   */

  const [isMatchModalOpen, setIsMatchModalOpen] = useState(false);

  const matching = (
    <Block as="section" utils={{ pb: 10, pt: 12 }} desktop={{ pt: 0, pb: 0 }}>
      <Container>
        <Row utils={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <Col span="45%" mobile={{ display: 'none' }}>
            <Card icon="clipboard" utils={{ boxShadow: 5 }}>
              <CardBody>
                <TestMatchForm />
              </CardBody>
            </Card>
          </Col>
          <Col span="100%" desktop={{ span: '45%' }}>
            <Heading as="h6" utils={{ fontSize: 'xs', textTransform: 'uppercase', color: 'primary', mb: 6 }}>
              Lender Matching
            </Heading>
            <Heading as="h1" utils={{ fontSize: '6xl', fontWeight: 'bolder', mb: 6 }}>
              Match your deal with the ideal capital source
            </Heading>
            <Text utils={{ fontSize: 'lg', color: 'gray800', mb: 9 }}>
              Our capital markets team connects monthly with private lenders, investors, and banks to maintain an up-to-date understanding of liquidity levels,
              program details, recently funded deals, decision makers, and more.
            </Text>
            <Row>
              <Col span="auto">
                <Heading as="h6" utils={{ textTransform: 'uppercase', color: 'gray700' }}>
                  Lenders
                </Heading>
                <Heading as="span" utils={{ fontSize: '5xl', lineHeight: 1.5 }}>
                  5,462
                </Heading>
              </Col>
              <Col span="auto">
                <Heading as="h6" utils={{ textTransform: 'uppercase', color: 'gray700' }}>
                  Programs
                </Heading>
                <Heading as="span" utils={{ fontSize: '5xl', lineHeight: 1.5 }}>
                  10,233
                </Heading>
              </Col>
              <Col span="auto">
                <Heading as="h6" utils={{ textTransform: 'uppercase', color: 'gray700' }}>
                  Match Accuracy
                </Heading>
                <Heading as="span" utils={{ fontSize: '5xl', lineHeight: 1.5 }}>
                  95%+
                </Heading>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button variant="primary" utils={{ mt: 9 }} isBlock desktop={{ display: 'none' }} onClick={() => setIsMatchModalOpen(true)}>
                  Test run our Lender Match
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Block>
  );

  /**
   * Testimonials
   */

  const [sliderAutoadvance, setSliderAutoAdvance] = useState(false);
  const sliderParentRef = useRef<HTMLDivElement>(null);
  const sliderRef = useRef<Carousel>(null);

  const sliderOptions = {
    autoplay: true,
    autoplaySpeed: 10000,
    cssEase: 'cubic-bezier(0.5, 0.12, 0.2, 1)',
    dots: true,
    pauseOnFocus: true,
    pauseOnHover: false,
    speed: 300,
  };

  useEffect(function () {
    if ('IntersectionObserver' in window) {
      let slideEl: HTMLDivElement;
      const intersectionObserver = new IntersectionObserver(function (entries) {
        if (sliderRef.current) {
          if (entries[0].intersectionRatio <= 0) {
            sliderRef.current.slickPause();
          } else {
            sliderRef.current.slickPlay();
          }
        }

        setSliderAutoAdvance(entries[0].intersectionRatio > 0);
      });

      if (sliderParentRef.current) {
        slideEl = sliderParentRef.current;
      }

      intersectionObserver.observe(sliderParentRef.current!);
      return () => {
        if (slideEl) {
          intersectionObserver.unobserve(slideEl);
        }
      };
    }
  }, []);

  const testimonials = (
    <Block as="section" utils={{ pt: 10, pb: 12 }} desktop={{ py: 14 }}>
      <Container>
        <Row utils={{ justifyContent: 'center', position: 'relative' }}>
          <Col span="100%" desktop={{ span: '70%' }}>
            <div ref={sliderParentRef} onMouseDownCapture={() => setSliderAutoAdvance(false)}>
              <Slider autoadvance={sliderAutoadvance} ref={sliderRef} {...sliderOptions}>
                <Block utils={{ textAlign: 'center' }}>
                  <picture>
                    <source srcSet="/img/logos/marketspace.avif" type="image/avif" />
                    <source srcSet="/img/logos/marketspace.webp" type="image/webp" />
                    <Image
                      src="/img/logos/marketspace.jpg"
                      alt="Marketspace"
                      utils={{ mx: 'auto', mb: 9 }}
                      height={120}
                      style={{ maxHeight: 120, width: 'auto' }}
                      loading="lazy"
                    />
                  </picture>
                  <Text as="blockquote" utils={{ fontSize: '4xl', mb: 9 }}>
                    WelcomeLend setup and closed financing for our 155-unit ground-up multifamily project in Houston. Our goal was max leverage and speed of
                    close and WelcomeLend absolutely delivered. We look forward to working with them again.
                  </Text>
                  <Heading as="h6" utils={{ fontSize: 'lg', mb: 2 }}>
                    Owner and Developer
                  </Heading>
                  <Text as="span" utils={{ color: 'gray700' }}>
                    The Spot in Houston
                  </Text>
                </Block>
                <Block utils={{ textAlign: 'center' }}>
                  <picture>
                    <source srcSet="/img/logos/the-pad.avif" type="image/avif" />
                    <source srcSet="/img/logos/the-pad.webp" type="image/webp" />
                    <Image
                      src="/img/logos/the-pad.jpg"
                      alt="The Pad"
                      utils={{ mx: 'auto', mb: 9, height: '120px' }}
                      style={{ maxHeight: 120, width: 'auto' }}
                      loading="lazy"
                    />
                  </picture>
                  <Text as="blockquote" utils={{ fontSize: '4xl', mb: 9 }}>
                    We worked with the WelcomeLend team on a ground up construction loan for a boutique hotel outside of Summit County, CO. The team secured
                    financing quickly and in a creative structure allowing us to get the deal off the ground.
                  </Text>
                  <Heading as="h6" utils={{ fontSize: 'lg', mb: 2 }}>
                    Owner and Developer
                  </Heading>
                  <Text as="span" utils={{ color: 'gray700' }}>
                    The Pad Hotel
                  </Text>
                </Block>
                <Block utils={{ textAlign: 'center' }}>
                  <picture>
                    <source srcSet="/img/logos/bellaire.avif" type="image/avif" />
                    <source srcSet="/img/logos/bellaire.webp" type="image/webp" />
                    <Image
                      src="/img/logos/bellaire.jpg"
                      alt="Bellaire"
                      utils={{ mx: 'auto', mb: 9, height: '120px' }}
                      style={{ maxHeight: 120, width: 'auto' }}
                      loading="lazy"
                    />
                  </picture>
                  <Text as="blockquote" utils={{ fontSize: '4xl', mb: 9 }}>
                    WelcomeLend was our exclusive group responsible for securing over $50mm in financing our two Senior Living projects in Houston. They
                    structured a full capital solution that more than met the needs of the project and partnership.
                  </Text>
                  <Heading as="h6" utils={{ fontSize: 'lg', mb: 2 }}>
                    Owner and Developer
                  </Heading>
                  <Text as="span" utils={{ color: 'gray700' }}>
                    Bellaire Senior Living
                  </Text>
                </Block>
                <Block utils={{ textAlign: 'center' }}>
                  <picture>
                    <source srcSet="/img/logos/alliance.avif" type="image/avif" />
                    <source srcSet="/img/logos/alliance.webp" type="image/webp" />
                    <Image
                      src="/img/logos/alliance.jpg"
                      alt="Alliance"
                      utils={{ mx: 'auto', mb: 9, height: '120px' }}
                      style={{ maxHeight: 120, width: 'auto' }}
                      loading="lazy"
                    />
                  </picture>
                  <Text as="blockquote" utils={{ fontSize: '4xl', mb: 9 }}>
                    WelcomeLend was responsible for securing our full capital stack for our Lakewood townhome project. Through their efforts, they successfully
                    secured financing that both fit our needs and closed in our time frame.
                  </Text>
                  <Heading as="h6" utils={{ fontSize: 'lg', mb: 2 }}>
                    Owner and Developer
                  </Heading>
                  <Text as="span" utils={{ color: 'gray700' }}>
                    Pierce St. Townhome Development
                  </Text>
                </Block>
              </Slider>
            </div>
          </Col>
        </Row>
      </Container>
    </Block>
  );

  /**
   * About #1
   */

  const cardImageSliderRef = useRef<Carousel>(null);

  const cardImageSliderOptions = {
    arrows: false,
    dots: false,
    fade: true,
    swipeToSlide: false,
  };

  const cardBodySliderOptions = {
    asNavFor: cardImageSliderRef.current!,
    speed: 300,
    cssEase: 'cubic-bezier(0.5, 0.12, 0.2, 1)',
    dots: false,
  };

  const aboutOne = (
    <Block as="section" utils={{ py: 10 }} desktop={{ py: 0 }}>
      <Container>
        <Row utils={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <Col span="100%" desktop={{ span: '45%' }}>
            <Heading as="h6" utils={{ fontSize: 'xs', textTransform: 'uppercase', color: 'primary', mb: 6 }}>
              Recent Deals
            </Heading>
            <Heading as="h1" utils={{ fontSize: '6xl', fontWeight: 'bolder', mb: 6 }}>
              We closed over $800M+ during a global pandemic.
            </Heading>
            <Text utils={{ fontSize: 'lg', color: 'gray800', mb: 9 }}>
              The past few years have been anything but normal, but our team has continued to close debt, equity, and structured finance deals for all assets
              classes.
            </Text>
            <ListIcon variant="primary" utils={{ color: 'gray800', mb: 9 }}>
              <ListIconItem>Average close time is just under 30 days</ListIconItem>
              <ListIconItem>Funded tough to finance assets, including hospitality</ListIconItem>
              <ListIconItem>Ground up, Bridge, Equity, PACE, Mezzanine, etc.</ListIconItem>
            </ListIcon>
            <Link href="/closed-deals" passHref legacyBehavior>
              <Button as="a" utils={{ mb: 8 }} desktop={{ mb: 0 }}>
                View Recently Closed Deals
              </Button>
            </Link>
          </Col>
          <Col span="100%" desktop={{ span: '45%' }}>
            <CardStack>
              <Card utils={{ border: 0 }}>
                <CardTop utils={{ overflow: 'hidden' }}>
                  <CardSlider ref={cardImageSliderRef} {...cardImageSliderOptions}>
                    <picture>
                      <source srcSet="/img/past-projects/the-pad@2x.avif 2x, /img/past-projects/the-pad.avif" type="image/avif" />
                      <source srcSet="/img/past-projects/the-pad@2x.webp 2x, /img/past-projects/the-pad.webp" type="image/webp" />
                      <Image
                        src="/img/past-projects/the-pad.jpg"
                        srcSet="/img/past-projects/the-pad@2x.jpg 2x"
                        alt="The Pad"
                        isFluid
                        height={473}
                        loading="lazy"
                      />
                    </picture>
                    <picture>
                      <source srcSet="/img/past-projects/the-spot@2x.avif 2x, /img/past-projects/the-spot.avif" type="image/avif" />
                      <source srcSet="/img/past-projects/the-spot@2x.webp 2x, /img/past-projects/the-spot.webp" type="image/webp" />
                      <Image
                        src="/img/past-projects/the-spot.jpg"
                        srcSet="/img/past-projects/the-spot@2x.jpg 2x"
                        alt="The Spot"
                        isFluid
                        height={473}
                        loading="lazy"
                      />
                    </picture>
                    <picture>
                      <source srcSet="/img/past-projects/denver-townhomes@2x.avif 2x, /img/past-projects/denver-townhomes.avif" type="image/avif" />
                      <source srcSet="/img/past-projects/denver-townhomes@2x.webp 2x, /img/past-projects/denver-townhomes.webp" type="image/webp" />
                      <Image
                        src="/img/past-projects/denver-townhomes.jpg"
                        srcSet="/img/past-projects/denver-townhomes@2x.jpg 2x"
                        alt="Denver townhomes"
                        isFluid
                        height={473}
                        loading="lazy"
                      />
                    </picture>
                    <picture>
                      <source srcSet="/img/past-projects/bellaire@2x.avif 2x, /img/past-projects/bellaire.avif" type="image/avif" />
                      <source srcSet="/img/past-projects/bellaire@2x.webp 2x, /img/past-projects/bellaire.webp" type="image/webp" />
                      <Image
                        src="/img/past-projects/bellaire.jpg"
                        srcSet="/img/past-projects/bellaire@2x.jpg 2x"
                        alt="Bellaire"
                        isFluid
                        height={473}
                        loading="lazy"
                      />
                    </picture>
                    <picture>
                      <source srcSet="/img/past-projects/pierce-st@2x.avif 2x, /img/past-projects/pierce-st.avif" type="image/avif" />
                      <source srcSet="/img/past-projects/pierce-st@2x.webp 2x, /img/past-projects/pierce-st.webp" type="image/webp" />
                      <Image
                        src="/img/past-projects/pierce-st.jpg"
                        srcSet="/img/past-projects/pierce-st@2x.jpg 2x"
                        alt="pierce-st"
                        isFluid
                        height={473}
                        loading="lazy"
                      />
                    </picture>
                  </CardSlider>
                </CardTop>
                <CardSlider {...cardBodySliderOptions}>
                  <CardBody>
                    <Heading as="h3" utils={{ fontSize: '2xl' }}>
                      ThePad Hotel
                    </Heading>
                    <Text utils={{ fontSize: 'sm', color: 'gray700', mb: 8 }}>Silverthorne, CO</Text>
                    <Divider utils={{ my: 0 }} />
                    <Row utils={{ alignItems: 'stretch' }} gutter={4}>
                      <Col span="100%" desktop={{ span: true }}>
                        <FlexRow utils={{ alignItems: 'center', py: 6 }}>
                          <Block utils={{ flexBasis: '100%' }}>
                            <Text utils={{ fontSize: 'sm', color: 'gray800' }}>Loan Request</Text>
                            <Heading as="span">$13mm</Heading>
                          </Block>
                          <Image src="/img/line-icons/svg/money.svg" alt="..." style={{ width: 42, height: 42 }} />
                        </FlexRow>
                      </Col>
                      <Col span="100%" desktop={{ span: 'auto' }}>
                        <Block utils={{ height: '100%', borderLeft: 1 }} mobile={{ display: 'none' }} />
                        <Divider utils={{ my: 0 }} desktop={{ display: 'none' }} />
                      </Col>
                      <Col span="100%" desktop={{ span: true }}>
                        <FlexRow utils={{ alignItems: 'center', py: 6 }}>
                          <Block utils={{ flexBasis: '100%' }}>
                            <Text utils={{ fontSize: 'sm', color: 'gray800' }}>Deal Type</Text>
                            <Heading as="span">Ground Up</Heading>
                          </Block>
                          <Image src="/img/line-icons/svg/ground-up.svg" alt="..." style={{ width: 42, height: 42 }} />
                        </FlexRow>
                      </Col>
                    </Row>
                    <Divider utils={{ my: 0 }} />
                    <Heading as="h4" utils={{ mt: 8, mb: 4 }}>
                      Our Results
                    </Heading>
                    <Text utils={{ color: 'gray800' }}>
                      WelcomeLend set up a senior lending facility + PACE to get the project fully funded through opening. We created flexibility to fully fund
                      construction while leaving aside considerable proceeds to open the business.
                    </Text>
                  </CardBody>
                  <CardBody>
                    <Heading as="h3" utils={{ fontSize: '2xl' }}>
                      TheSpot Multifamily
                    </Heading>
                    <Text utils={{ fontSize: 'sm', color: 'gray700', mb: 8 }}>Houston, TX</Text>
                    <Divider utils={{ my: 0 }} />
                    <Row utils={{ alignItems: 'stretch' }} gutter={4}>
                      <Col span="100%" desktop={{ span: true }}>
                        <FlexRow utils={{ alignItems: 'center', py: 6 }}>
                          <Block utils={{ flexBasis: '100%' }}>
                            <Text utils={{ fontSize: 'sm', color: 'gray800' }}>Loan Request</Text>
                            <Heading as="span">$17mm</Heading>
                          </Block>
                          <Image src="/img/line-icons/svg/money.svg" alt="..." style={{ width: 42, height: 42 }} />
                        </FlexRow>
                      </Col>
                      <Col span="100%" desktop={{ span: 'auto' }}>
                        <Block utils={{ height: '100%', borderLeft: 1 }} mobile={{ display: 'none' }} />
                        <Divider utils={{ my: 0 }} desktop={{ display: 'none' }} />
                      </Col>
                      <Col span="100%" desktop={{ span: true }}>
                        <FlexRow utils={{ alignItems: 'center', py: 6 }}>
                          <Block utils={{ flexBasis: '100%' }}>
                            <Text utils={{ fontSize: 'sm', color: 'gray800' }}>Deal Type</Text>
                            <Heading as="span">Ground Up</Heading>
                          </Block>
                          <Image src="/img/line-icons/svg/ground-up.svg" alt="..." style={{ width: 42, height: 42 }} />
                        </FlexRow>
                      </Col>
                    </Row>
                    <Divider utils={{ my: 0 }} />
                    <Heading as="h4" utils={{ mt: 8, mb: 4 }}>
                      Our Results
                    </Heading>
                    <Text utils={{ color: 'gray800' }}>
                      WelcomeLend closed this loan in 15 days. The group had a bank deal setup but was stalled out due to COVID. WelcomeLend put together a
                      high-leverage stretch senior loan that got the project fully funded.
                    </Text>
                  </CardBody>
                  <CardBody>
                    <Heading as="h3" utils={{ fontSize: '2xl' }}>
                      Denver University Townhomes
                    </Heading>
                    <Text utils={{ fontSize: 'sm', color: 'gray700', mb: 8 }}>Denver, CO</Text>
                    <Divider utils={{ my: 0 }} />
                    <Row utils={{ alignItems: 'stretch' }} gutter={4}>
                      <Col span="100%" desktop={{ span: true }}>
                        <FlexRow utils={{ alignItems: 'center', py: 6 }}>
                          <Block utils={{ flexBasis: '100%' }}>
                            <Text utils={{ fontSize: 'sm', color: 'gray800' }}>Loan Request</Text>
                            <Heading as="span">$8mm</Heading>
                          </Block>
                          <Image src="/img/line-icons/svg/money.svg" alt="..." style={{ width: 42, height: 42 }} />
                        </FlexRow>
                      </Col>
                      <Col span="100%" desktop={{ span: 'auto' }}>
                        <Block utils={{ height: '100%', borderLeft: 1 }} mobile={{ display: 'none' }} />
                        <Divider utils={{ my: 0 }} desktop={{ display: 'none' }} />
                      </Col>
                      <Col span="100%" desktop={{ span: true }}>
                        <FlexRow utils={{ alignItems: 'center', py: 6 }}>
                          <Block utils={{ flexBasis: '100%' }}>
                            <Text utils={{ fontSize: 'sm', color: 'gray800' }}>Deal Type</Text>
                            <Heading as="span">Ground Up</Heading>
                          </Block>
                          <Image src="/img/line-icons/svg/ground-up.svg" alt="..." style={{ width: 42, height: 42 }} />
                        </FlexRow>
                      </Col>
                    </Row>
                    <Divider utils={{ my: 0 }} />
                    <Heading as="h4" utils={{ mt: 8, mb: 4 }}>
                      Our Results
                    </Heading>
                    <Text utils={{ color: 'gray800' }}>
                      WelcomeLend closed within 10 days by setting up a stretch senior option with a family office looking to expand its lending operations in
                      Denver. The loan was interest only with a rate in the mid single digits.
                    </Text>
                  </CardBody>
                  <CardBody>
                    <Heading as="h3" utils={{ fontSize: '2xl' }}>
                      Bellaire Independent Living
                    </Heading>
                    <Text utils={{ fontSize: 'sm', color: 'gray700', mb: 8 }}>Houston, TX</Text>
                    <Divider utils={{ my: 0 }} />
                    <Row utils={{ alignItems: 'stretch' }} gutter={4}>
                      <Col span="100%" desktop={{ span: true }}>
                        <FlexRow utils={{ alignItems: 'center', py: 6 }}>
                          <Block utils={{ flexBasis: '100%' }}>
                            <Text utils={{ fontSize: 'sm', color: 'gray800' }}>Loan Request</Text>
                            <Heading as="span">$26mm</Heading>
                          </Block>
                          <Image src="/img/line-icons/svg/money.svg" alt="..." style={{ width: 42, height: 42 }} />
                        </FlexRow>
                      </Col>
                      <Col span="100%" desktop={{ span: 'auto' }}>
                        <Block utils={{ height: '100%', borderLeft: 1 }} mobile={{ display: 'none' }} />
                        <Divider utils={{ my: 0 }} desktop={{ display: 'none' }} />
                      </Col>
                      <Col span="100%" desktop={{ span: true }}>
                        <FlexRow utils={{ alignItems: 'center', py: 6 }}>
                          <Block utils={{ flexBasis: '100%' }}>
                            <Text utils={{ fontSize: 'sm', color: 'gray800' }}>Deal Type</Text>
                            <Heading as="span">Ground Up</Heading>
                          </Block>
                          <Image src="/img/line-icons/svg/ground-up.svg" alt="..." style={{ width: 42, height: 42 }} />
                        </FlexRow>
                      </Col>
                    </Row>
                    <Divider utils={{ my: 0 }} />
                    <Heading as="h4" utils={{ mt: 8, mb: 4 }}>
                      Our Results
                    </Heading>
                    <Text utils={{ color: 'gray800' }}>
                      WelcomeLend structured a loan to fully fund construction and OpEx to build and open this 125 bed community. The team creatively structured
                      a lending facility that allowed for land equity to replace hard cash.
                    </Text>
                  </CardBody>
                  <CardBody>
                    <Heading as="h3" utils={{ fontSize: '2xl' }}>
                      Pierce St Townhomes
                    </Heading>
                    <Text utils={{ fontSize: 'sm', color: 'gray700', mb: 8 }}>Denver, CO</Text>
                    <Divider utils={{ my: 0 }} />
                    <Row utils={{ alignItems: 'stretch' }} gutter={4}>
                      <Col span="100%" desktop={{ span: true }}>
                        <FlexRow utils={{ alignItems: 'center', py: 6 }}>
                          <Block utils={{ flexBasis: '100%' }}>
                            <Text utils={{ fontSize: 'sm', color: 'gray800' }}>Loan Request</Text>
                            <Heading as="span">$7mm</Heading>
                          </Block>
                          <Image src="/img/line-icons/svg/money.svg" alt="..." style={{ width: 42, height: 42 }} />
                        </FlexRow>
                      </Col>
                      <Col span="100%" desktop={{ span: 'auto' }}>
                        <Block utils={{ height: '100%', borderLeft: 1 }} mobile={{ display: 'none' }} />
                        <Divider utils={{ my: 0 }} desktop={{ display: 'none' }} />
                      </Col>
                      <Col span="100%" desktop={{ span: true }}>
                        <FlexRow utils={{ alignItems: 'center', py: 6 }}>
                          <Block utils={{ flexBasis: '100%' }}>
                            <Text utils={{ fontSize: 'sm', color: 'gray800' }}>Deal Type</Text>
                            <Heading as="span">Ground Up</Heading>
                          </Block>
                          <Image src="/img/line-icons/svg/ground-up.svg" alt="..." style={{ width: 42, height: 42 }} />
                        </FlexRow>
                      </Col>
                    </Row>
                    <Divider utils={{ my: 0 }} />
                    <Heading as="h4" utils={{ mt: 8, mb: 4 }}>
                      Our Results
                    </Heading>
                    <Text utils={{ color: 'gray800' }}>
                      Welcomelend setup a senior and mezzanine loan to bring in a high-leverage option. This allowed the team to minimize cash equity within a
                      high-leverage lending facility that still blended out with a single digit rate.
                    </Text>
                  </CardBody>
                </CardSlider>
              </Card>
            </CardStack>
          </Col>
        </Row>
      </Container>
    </Block>
  );

  /**
   * About #2
   */

  const aboutTwo = (
    <Block as="section" utils={{ pt: 12 }} desktop={{ pt: 14 }}>
      <Container>
        <Row utils={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <Col span="100%" desktop={{ span: '45%', order: 1 }}>
            <Heading as="h6" utils={{ fontSize: 'xs', textTransform: 'uppercase', color: 'primary', mb: 6 }}>
              Offering Memorandum
            </Heading>
            <Heading as="h1" utils={{ fontSize: '6xl', fontWeight: 'bolder', mb: 6 }}>
              Beautiful & interactive online OMs for free.
            </Heading>
            <Text utils={{ fontSize: 'lg', color: 'gray800', mb: 9 }}>
              Professional OMs are critical to securing deal funding. Our OMs are proven to increase the odds of receiving a term sheet.
            </Text>
            <ListIcon variant="primary" utils={{ color: 'gray800', mb: 8 }} desktop={{ mb: 0 }}>
              <ListIconItem>Automatically generated, fully interactive map</ListIconItem>
              <ListIconItem>Rich market data powered by our own data infrastructure</ListIconItem>
              <ListIconItem>Clear financial overview for easy lender review</ListIconItem>
              <ListIconItem>Comprehensive profile of the borrowing team</ListIconItem>
            </ListIcon>
          </Col>
          <Col span="100%" desktop={{ span: '60%', ml: '-15%', order: 0 }}>
            <picture>
              <source srcSet="/img/screenshots/screenshot-3@2x.avif 2x, /img/screenshots/screenshot-3.avif" type="image/avif" />
              <source srcSet="/img/screenshots/screenshot-3@2x.webp 2x, /img/screenshots/screenshot-3.webp" type="image/webp" />
              <Image
                alt="..."
                utils={{ position: 'relative', borderRadius: 'base', boxShadow: 6 }}
                height={659}
                isFluid
                loading="lazy"
                src="/img/screenshots/screenshot-3.jpg"
                srcSet="/img/screenshots/screenshot-3@2x.jpg 2x"
                style={{ zIndex: 1 }}
                width={644}
              />
            </picture>
          </Col>
        </Row>
      </Container>
    </Block>
  );

  /**
   * CTA
   */

  const cta = (
    <>
      <Block utils={{ position: 'relative' }}>
        <Shape horizontal="left" indent={15} slant={8} variant="black" desktop={{ indent: 0, slant: 15 }} />
      </Block>
      <Block as="section" utils={{ py: 11, bgColor: 'black' }} desktop={{ py: 18 }}>
        <Container>
          <Row utils={{ justifyContent: 'center' }}>
            <Col span="100%" desktop={{ span: '65%' }}>
              <Heading as="h6" utils={{ fontSize: 'xs', textAlign: 'center', textTransform: 'uppercase', color: 'primary', mb: 6 }}>
                Give us a try
              </Heading>
              <Heading as="h1" utils={{ textAlign: 'center', color: 'white', fontSize: '7xl', fontWeight: 'bolder', mb: 6 }}>
                WelcomeLend makes financing your deal easy.
              </Heading>
              <Text utils={{ textAlign: 'center', fontSize: '2xl', color: 'gray800', mb: 9 }}>
                Our capital markets team is 100% focused on finding you the perfect funding source and structure for your commercial real estate deal.
              </Text>
              <Form
                utils={{ maxWidth: '420px', mx: 'auto', mb: 0 }}
                onSubmit={(e) => {
                  e.preventDefault();
                  const email = contactFormContext.contactForm.email;
                  if (email.length !== 0) {
                    contactFormContext.setIsContactModalOpen(true);
                    (e.target as HTMLFormElement).reset();
                  }
                }}
              >
                <FlexRow>
                  <Input
                    utils={{ bgColor: 'white10', borderColor: 'transparent', boxShadow: 'none', color: 'white', mr: 6 }}
                    type="email"
                    name="email"
                    placeholder="Email"
                    required
                    onChange={(e) => {
                      const email = e.target.value;
                      contactFormContext.setContactFormEmail(email);
                    }}
                  />
                  <Button>Get Started</Button>
                </FlexRow>
              </Form>
            </Col>
          </Row>
        </Container>
      </Block>
    </>
  );

  /**
   * Footer
   */

  const footer = (
    <Block as="footer" utils={{ bgColor: 'black' }}>
      <Container>
        <Row>
          <Col>
            <Divider utils={{ my: 0, borderColor: 'white10' }} />
          </Col>
        </Row>
        <Row utils={{ alignItems: 'center', py: 8 }}>
          <Col span="100%" desktop={{ span: 'auto' }}>
            <FlexRow utils={{ alignItems: 'center', justifyContent: 'center' }}>
              <Image src="/img/brand/brand.svg" style={{ width: 32, height: 'auto' }} />
              <Heading utils={{ fontSize: 'base', color: 'white', ml: 4 }} mobile={{ display: 'none' }}>
                WelcomeLend
              </Heading>
            </FlexRow>
          </Col>
          <Col span="100%" utils={{ mt: 8, mb: 7 }} desktop={{ span: true, mt: 0, mb: 0 }}>
            <Scrollable utils={{ justifyContent: 'center' }}>
              <Nav>
                <Link href="/" passHref legacyBehavior>
                  <Text as="a" utils={{ color: 'gray700', mx: 4, whiteSpace: 'nowrap' }}>
                    Product
                  </Text>
                </Link>
                <Link href="/company" passHref legacyBehavior>
                  <Text as="a" utils={{ color: 'gray700', mx: 4, whiteSpace: 'nowrap' }}>
                    Company
                  </Text>
                </Link>
                <Link href="/closed-deals" passHref legacyBehavior>
                  <Text as="a" utils={{ color: 'gray700', mx: 4, whiteSpace: 'nowrap' }}>
                    Closed Deals
                  </Text>
                </Link>
                <Link href="/" passHref scroll={false} legacyBehavior>
                  <Text as="a" utils={{ color: 'gray700', mx: 4, whiteSpace: 'nowrap' }} onClick={() => contactFormContext.setIsContactModalOpen(true)}>
                    Contact
                  </Text>
                </Link>
              </Nav>
            </Scrollable>
          </Col>
          <Col span="100%" utils={{ textAlign: 'center' }} desktop={{ span: 'auto' }}>
            <Text as="span" utils={{ fontSize: 'sm', color: 'gray700' }}>
              Copyright {currentYear}
            </Text>
          </Col>
        </Row>
      </Container>
    </Block>
  );

  return (
    <>
      <Head>
        <title>WelcomeLend</title>
        <meta
          name="description"
          content="WelcomeLend is a Commercial Real Estate platform that makes it easy to collect and review documents, build an OM, and connect sponsors and lenders all in one secure place."
        />
        <link rel="preload" as="image" href="/img/screenshots/screenshot-1.avif" imageSrcSet="/img/screenshots/screenshot-1@2x.avif 2x" />
        <link rel="preload" as="image" href="/img/screenshots/screenshot-2.avif" imageSrcSet="/img/screenshots/screenshot-2@2x.avif 2x" />
      </Head>
      {welcome}
      {process}
      {matching}
      {testimonials}
      {aboutOne}
      {aboutTwo}
      {cta}
      {footer}

      {/* Modals */}
      <TestMatchModal isOpen={isMatchModalOpen} onClose={() => setIsMatchModalOpen(false)} />
    </>
  );
}

Homepage.Layout = FrontLayout;

export default Homepage;
