import { List } from '~/components/list';
import ProcessListItem from './ProcessListItem';
import styled, { css } from 'styled-components';

type ProcessListType = {
  autoplay?: boolean;
};

const ProcessList = styled(List).attrs<ProcessListType>(() => ({ gutter: 9 }))<ProcessListType>`
  ${({ autoplay }) => css`
    ${!autoplay &&
    `
      & > ${ProcessListItem.styledComponent}::after {
        display: none;
      }
    `}
  `}
`;

export default ProcessList;
