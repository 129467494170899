import { Image } from '~/components/ui';
import styled, { css } from 'styled-components';

const ProcessMainIcon = styled(Image)`
  ${() => css`
    width: 100%;
    height: 100%;
  `}
`;

export default ProcessMainIcon;
