import React from 'react';
import { fadeIn } from '~/helpers';
import { ListItem } from '~/components/list';
import { progressY } from '~/components/animations';
import Base, { BaseHOCPropsWithoutRef } from '~/components/layout/Base';
import styled, { css } from 'styled-components';

const StyledItemIcon = styled(Base)`
  ${({ theme }) => css`
    position: relative;
    z-index: 1;
    margin-bottom: ${theme.spacers[3]}px;
    transition: ${theme.transition.base};
    transitiion-property: color;

    & > svg {
      width: 2.5rem;
      height: 2.5rem;
    }
  `}
`;

const StyledItemContent = styled(Base)`
  ${({ theme }) => css`
    transition: ${theme.transition.base};
    transitiion-property: opacity;
  `}
`;

type StyledIconProps = {
  isActive?: boolean;
};

const StyledItem = styled(ListItem)<StyledIconProps>`
  ${({ isActive, theme }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    @media (min-width: ${theme.breakpoints.desktop}px) {
      flex-direction: row;

      &::after,
      &::before {
        content: '';
        position: absolute;
        top: ${theme.spacers[9]}px;
        left: 1.25rem;
        width: ${theme.borderWidth}px;
        height: calc(100% + 0.75em);
        background-color: ${theme.colors.primary};
      }

      &:last-child::after,
      &:last-child::before {
        display: none;
      }

      &::after {
        transform: scale(1, 0);
        transform-origin: center top;
        animation-fill-mode: forwards;
      }
    }

    /* 
     * Icon
     */

    & > ${StyledItemIcon} {
      max-width: 2.5rem;
      margin-right: ${theme.spacers[7]}px;
      color: ${theme.colors.primary};
    }

    /*
     * Active
     */

    ${isActive &&
    css`
      @media (min-width: ${theme.breakpoints.desktop}px) {
        && ~ * > ${StyledItemIcon} {
          color: ${theme.colors.gray800};
        }

        && ~ * > ${StyledItemContent} {
          opacity: 0.2;

          &:hover {
            opacity: 0.4;
          }
        }

        &::after {
          animation: 5s ${progressY} linear;
        }

        &::before,
        && ~ *::before {
          background-color: ${fadeIn(theme.borderColor, 0.2)};
        }
      }
    `}
  `}
`;

type ProcessListItemType = {
  <C extends React.ElementType = 'li'>(props: ProcessListItemProps<C> & { ref?: React.Ref<HTMLLIElement> }): React.ReactNode;
  displayName?: string | undefined;
  styledComponent?: typeof StyledItem;
};

type ProcessListItemInnerProps = StyledIconProps & {
  icon?: React.ReactNode;
};

type ProcessListItemProps<C extends React.ElementType = 'button'> = BaseHOCPropsWithoutRef<C, ProcessListItemInnerProps>;

const ProcessListItem: ProcessListItemType = React.forwardRef(
  <C extends React.ElementType = 'li'>({ as, icon, ...props }: ProcessListItemProps<C>, ref: React.Ref<HTMLLIElement>) => (
    <StyledItem ref={ref} as={as as React.ElementType} {...props}>
      <StyledItemIcon>{icon}</StyledItemIcon>
      <StyledItemContent>{props.children}</StyledItemContent>
    </StyledItem>
  ),
);

ProcessListItem.displayName = 'ProcessListItem';
ProcessListItem.styledComponent = StyledItem;
export default ProcessListItem;
