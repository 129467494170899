import { Base } from '~/components/layout';
import styled, { css } from 'styled-components';

const ProcessItem = styled(Base)`
  ${() => css`
    position: absolute;
    width: 10%;
    height: 10%;
    text-align: center;
    transform: translate(-50%, -50%);
  `}
`;

export default ProcessItem;
