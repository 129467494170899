/* eslint-disable max-len */
import React from 'react';

const IconMailUrgent = (props: React.ComponentProps<'svg'>) => {
  return (
    <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M27 21.8V14L18 26H22.2V34.4L31.8 21.8H27Z" fill="white" />
      <path
        opacity="0.6"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.4074 30L31.3333 22H26.8889V14L18 26H22.4444V30H12C10.8954 30 10 29.1046 10 28V8C10 6.89543 10.8954 6 12 6H36C37.1046 6 38 6.89543 38 8V28C38 29.1046 37.1046 30 36 30H25.4074Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.6086 23.8284L18 26H22.4444V34L31.3333 22H30.9231L40.4145 15.1451C40.8622 14.8217 41.4873 14.9225 41.8107 15.3703C41.9338 15.5407 42 15.7456 42 15.9558V36C42 38.2091 40.2091 40 38 40H10C7.79086 40 6 38.2091 6 36V15.9558C6 15.4035 6.44772 14.9558 7 14.9558C7.21021 14.9558 7.41508 15.022 7.58549 15.1451L19.6086 23.8284Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconMailUrgent;
