/* eslint-disable max-len */
import React from 'react';

const IconMonitor = (props: React.ComponentProps<'svg'>) => {
  return (
    <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path opacity="0.6" fillRule="evenodd" clipRule="evenodd" d="M10.0006 13.9999V29.9999H38.0006V13.9999H10.0006Z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 38V32C22 30.8954 22.8954 30 24 30C25.1046 30 26 30.8954 26 32V38H29C29.5523 38 30 38.4477 30 39C30 39.5523 29.5523 40 29 40H19C18.4477 40 18 39.5523 18 39C18 38.4477 18.4477 38 19 38H22Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 14.0001V30.0001H38V14.0001H10ZM10.5 10.0001H37.5C39.9853 10.0001 42 11.7909 42 14.0001V30.0001C42 32.2092 39.9853 34.0001 37.5 34.0001H10.5C8.01472 34.0001 6 32.2092 6 30.0001V14.0001C6 11.7909 8.01472 10.0001 10.5 10.0001Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconMonitor;
