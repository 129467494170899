import { Base } from '~/components/layout';
import ProcessMainCaption from './ProcessMainCaption';
import styled, { css } from 'styled-components';

type ProcessMainProps = {
  step: 0 | 1 | 2 | 3;
};

const ProcessMain = styled(Base).attrs<ProcessMainProps>(({ step }) => ({
  style: {
    transform: `translate(-50%, ${step === 0 ? '725%' : step === 1 ? '825%' : step === 2 ? '500%' : step === 3 ? '175%' : '0%'})`,
  },
}))<ProcessMainProps>`
  ${({ step, theme }) => css`
    position: absolute;
    width: 11.5%;
    height: 10%;
    text-align: center;
    transition: all 0.6s cubic-bezier(0.65, -0.3, 0, 1.2);

    ${ProcessMainCaption} {
      top: ${step === 3 && `calc((${theme.spacers[4]}px + ${theme.lineHeight.sm} * 1em) * -1)`};
    }
  `}
`;

export default ProcessMain;
