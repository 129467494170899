import { Base } from '~/components/layout';
import styled, { css } from 'styled-components';

type ProcessArrowProps = {
  x1: number;
  x2: number;
  y1: number;
  y2: number;
  order?: 1 | 2 | 3 | 4;
  isActive?: boolean;
};

const ProcessArrow = styled(Base)<ProcessArrowProps>`
  ${({ x1, y1, x2, y2, order, isActive }) => css`
    position: absolute;
    top: ${y1}%;
    left: ${x1}%;
    width: 6px;
    height: ${Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2))}%;
    margin-left: ${x1 === x2 && '-3px'};
    opacity: ${isActive ? 1 : 0};
    clip-path: ${isActive ? 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)' : 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)'};
    overflow: hidden;
    transform-origin: center top;
    transition: clip-path 0.6s, opacity 0.6s;
    transition-delay: ${isActive && order && `${order * 0.6}s`};

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 59%;
      max-height: 160px;
      background-repeat: no-repeat;
      background-image: url('/img/process/arrow.svg');
      background-position: center center;
      background-size: 6px 160px;
      transform: translateY(-50%);
    }

    ${x2 > x1 &&
    y2 > y1 &&
    `
      transform: rotate(${Math.round(Math.atan((y2 - y1) / (x2 - x1)) * (180 / Math.PI)) - 90}deg);
    `}

    ${x2 < x1 &&
    y2 > y1 &&
    `
      transform: rotate(${Math.round(Math.atan((y2 - y1) / (x2 - x1)) * (180 / Math.PI)) + 90}deg);
    `}

    ${x2 > x1 &&
    y2 < y1 &&
    `
      transform: rotate(${Math.round(Math.atan((y2 - y1) / (x2 - x1)) * (180 / Math.PI)) - 90}deg);
    `}
    
    ${x2 < x1 &&
    y2 < y1 &&
    `
      transform: rotate(${Math.round(Math.atan((y2 - y1) / (x2 - x1)) * (180 / Math.PI)) - 270}deg);
    `}
  `}
`;

export default ProcessArrow;
