import { Text } from '~/components/type';
import styled, { css } from 'styled-components';

interface ProcessItemCaptionProps {
  order: 1 | 2 | 3 | 4;
  isActive?: boolean;
}

const ProcessItemCaption = styled(Text)<ProcessItemCaptionProps>`
  ${({ order, isActive, theme }) => css`
    position: absolute;
    left: 50%;
    bottom: 100%;
    width: 110px;
    margin-bottom: ${theme.spacers[4]}px;
    line-height: ${theme.lineHeight.sm};
    font-size: ${theme.fontSize.xs}px;
    color: ${theme.colors.gray600};
    opacity: ${isActive ? 1 : 0};
    transform: translateX(-50%) translateY(${isActive ? 0 : '10px'});
    transition: all 0.6s cubic-bezier(0.65, -0.3, 0, 1.2);
    transition-delay: ${isActive && order && `${order * 0.6}s`};
  `}
`;

export default ProcessItemCaption;
