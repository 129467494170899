import React from 'react';
import { formatSVG } from '~/helpers';
import Base, { BaseHOCPropsWithoutRef } from '~/components/layout/Base';
import ProcessArrow from './ProcessArrow';
import ProcessContainer from './ProcessContainer';
import ProcessInner from './ProcessInner';
import ProcessItem from './ProcessItem';
import ProcessItemCaption from './ProcessItemCaption';
import ProcessItemIcon from './ProcessItemIcon';
import ProcessMain from './ProcessMain';
import ProcessMainCaption from './ProcessMainCaption';
import ProcessMainIcon from './ProcessMainIcon';
import styled, { css } from 'styled-components';

const StyledAnimations = styled(Base)`
  ${({ theme }) => css`
    border-radius: 1rem;
    border: ${theme.borderWidth}px solid #092547;
    background-color: ${theme.colors.black};
    background-image: ${formatSVG(
      '<svg viewBox=\'0 0 10 10\' xmlns=\'http://www.w3.org/2000/svg\'><circle cx=\'1\' cy=\'1\' r=\'1\' fill=\'white\' opacity=\'.05\'></circle></svg>',
    )};
    background-size: 10px 10px;
  `}
`;

type ProcessAnimationType = {
  <C extends React.ElementType = 'div'>(props: ProcessAnimationProps<C> & { ref?: React.Ref<HTMLDivElement> }): React.ReactNode;
  displayName?: string | undefined;
};

export type ProcessAnimationInnerStep = 0 | 1 | 2 | 3;

type ProcessAnimationInnerProps = {
  step: ProcessAnimationInnerStep;
};

type ProcessAnimationProps<C extends React.ElementType = 'div'> = BaseHOCPropsWithoutRef<C, ProcessAnimationInnerProps>;

const ProcessAnimation: ProcessAnimationType = React.forwardRef(
  <C extends React.ElementType = 'div'>({ as, step, ...props }: ProcessAnimationProps<C>, ref: React.Ref<HTMLDivElement>) => (
    <StyledAnimations ref={ref} as={as as React.ElementType} {...props}>
      <ProcessContainer>
        <ProcessInner>
          {/* Logo */}
          <ProcessMain step={step} style={{ top: '-5%', left: '50%' }}>
            <ProcessMainIcon src="/img/process/logo.svg" alt="logo" />
            <ProcessMainCaption>Our Platform</ProcessMainCaption>
          </ProcessMain>

          {/* Step 0 */}
          <ProcessItem style={{ top: '42.5%', left: '10%' }}>
            <ProcessItemCaption order={1} isActive={step === 0}>
              Public data
            </ProcessItemCaption>
            <ProcessItemIcon order={1} isActive={step === 0} src="/img/process/globe.svg" alt="globe" />
          </ProcessItem>
          <ProcessItem style={{ top: '27.5%', left: '50%' }}>
            <ProcessItemCaption order={1} isActive={step === 0}>
              Subscription data
            </ProcessItemCaption>
            <ProcessItemIcon order={1} isActive={step === 0} src="/img/process/server.svg" alt="server" />
          </ProcessItem>
          <ProcessItem style={{ top: '42.5%', left: '90%' }}>
            <ProcessItemCaption order={1} isActive={step === 0}>
              Owner info
            </ProcessItemCaption>
            <ProcessItemIcon order={1} isActive={step === 0} src="/img/process/contact.svg" alt="contact" />
          </ProcessItem>

          {/* Step 1 */}
          <ProcessItem style={{ top: '50%', left: '87.5%' }}>
            <ProcessItemCaption order={1} isActive={step === 1}>
              Reminders & feedback
            </ProcessItemCaption>
            <ProcessItemIcon order={1} isActive={step === 1} src="/img/process/clipboard-list.svg" alt="clipboard" />
          </ProcessItem>
          <ProcessItem style={{ top: '17.5%', left: '50%' }}>
            <ProcessItemCaption order={2} isActive={step === 1}>
              Sponsors
            </ProcessItemCaption>
            <ProcessItemIcon order={2} isActive={step === 1} src="/img/process/group.svg" alt="group" />
          </ProcessItem>
          <ProcessItem style={{ top: '50%', left: '12.5%' }}>
            <ProcessItemCaption order={3} isActive={step === 1}>
              Deal docs
            </ProcessItemCaption>
            <ProcessItemIcon order={3} isActive={step === 1} src="/img/process/file-done.svg" alt="done" />
          </ProcessItem>

          {/* Step 2 */}
          <ProcessItem style={{ top: '17.5%', left: '17.5%' }}>
            <ProcessItemCaption order={1} isActive={step === 2}>
              Deal docs
            </ProcessItemCaption>
            <ProcessItemIcon order={1} isActive={step === 2} src="/img/process/file-done.svg" alt="done" />
          </ProcessItem>
          <ProcessItem style={{ top: '17.5%', left: '50%' }}>
            <ProcessItemCaption order={1} isActive={step === 2}>
              Pictures
            </ProcessItemCaption>
            <ProcessItemIcon order={1} isActive={step === 2} src="/img/process/picture.svg" alt="picture" />
          </ProcessItem>
          <ProcessItem style={{ top: '17.5%', left: '82.5%' }}>
            <ProcessItemCaption order={1} isActive={step === 2}>
              Automated market info
            </ProcessItemCaption>
            <ProcessItemIcon order={1} isActive={step === 2} src="/img/process/chart-line.svg" alt="chart" />
          </ProcessItem>
          <ProcessItem style={{ top: '82.5%', left: '50%' }}>
            <ProcessItemIcon order={2} isActive={step === 2} src="/img/process/monitor.svg" alt="monitor" />
            <ProcessItemCaption order={2} isActive={step === 2}>
              Online OM
            </ProcessItemCaption>
          </ProcessItem>

          {/* Step 3 */}
          <ProcessItem style={{ top: '50%', left: '12.5%' }}>
            <ProcessItemCaption order={1} isActive={step === 3}>
              Deal docs
            </ProcessItemCaption>
            <ProcessItemIcon order={1} isActive={step === 3} src="/img/process/file-done.svg" alt="done" />
          </ProcessItem>
          <ProcessItem style={{ top: '50%', left: '87.5%' }}>
            <ProcessItemCaption order={1} isActive={step === 3}>
              Online OM
            </ProcessItemCaption>
            <ProcessItemIcon order={1} isActive={step === 3} src="/img/process/monitor.svg" alt="monitor" />
          </ProcessItem>
          <ProcessItem style={{ top: '82.5%', left: '50%' }}>
            <ProcessItemIcon order={2} isActive={step === 3} src="/img/process/group.svg" alt="group" />
            <ProcessItemCaption order={2} isActive={step === 3}>
              Lenders & Investors
            </ProcessItemCaption>
          </ProcessItem>

          {/* Arrows */}
          <ProcessArrow x1={10} y1={42.5} x2={50} y2={72.5} order={1} isActive={step === 0} />
          <ProcessArrow x1={90} y1={42.5} x2={50} y2={72.5} order={1} isActive={step === 0} />
          <ProcessArrow x1={50} y1={27.5} x2={50} y2={72.5} order={1} isActive={step === 0} />

          <ProcessArrow x1={50} y1={82.5} x2={87.5} y2={50} order={1} isActive={step === 1} />
          <ProcessArrow x1={87.5} y1={50} x2={50} y2={17.5} order={2} isActive={step === 1} />
          <ProcessArrow x1={50} y1={17.5} x2={12.5} y2={50} order={3} isActive={step === 1} />
          <ProcessArrow x1={12.5} y1={50} x2={50} y2={82.5} order={4} isActive={step === 1} />

          <ProcessArrow x1={17.5} y1={17.5} x2={50} y2={50} order={1} isActive={step === 2} />
          <ProcessArrow x1={50} y1={17.5} x2={50} y2={50} order={1} isActive={step === 2} />
          <ProcessArrow x1={82.5} y1={17.5} x2={50} y2={50} order={1} isActive={step === 2} />
          <ProcessArrow x1={50} y1={60} x2={50} y2={82.5} order={2} isActive={step === 2} />

          <ProcessArrow x1={50} y1={12.5} x2={12.5} y2={50} order={1} isActive={step === 3} />
          <ProcessArrow x1={12.5} y1={50} x2={50} y2={87.5} order={2} isActive={step === 3} />
          <ProcessArrow x1={50} y1={12.5} x2={87.5} y2={50} order={1} isActive={step === 3} />
          <ProcessArrow x1={87.5} y1={50} x2={50} y2={87.5} order={2} isActive={step === 3} />
        </ProcessInner>
      </ProcessContainer>
    </StyledAnimations>
  ),
);

ProcessAnimation.displayName = 'ProcessAnimation';
export default ProcessAnimation;
