/* eslint-disable max-len */
import React from 'react';

const IconGroup = (props: React.ComponentProps<'svg'>) => {
  return (
    <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4953 22.1667C14.4452 22.1667 11.162 18.8835 11.162 14.8334C11.162 10.7833 14.4452 7.50006 18.4953 7.50006C22.5454 7.50006 25.8287 10.7833 25.8287 14.8334C25.8287 18.8835 22.5454 22.1667 18.4953 22.1667Z"
        fill="currentColor"
      />
      <path
        opacity="0.6"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.2689 29.5023C40.5141 29.5706 45.6135 32.7281 45.9972 39.4012C46.0127 39.67 45.9972 40.5012 45.0026 40.5012H37.9333C37.9333 36.3745 36.5699 32.5662 34.2689 29.5023Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.00119 39.0331C2.71181 30.2831 9.81351 25.8345 18.4695 25.8345C27.2472 25.8345 34.459 30.0387 34.9962 39.0345C35.0176 39.3929 34.9962 40.5012 33.619 40.5012C26.8254 40.5012 16.7303 40.5012 3.33375 40.5012C2.87397 40.5012 1.96249 39.5097 2.00119 39.0331Z"
        fill="currentColor"
      />
      <path
        opacity="0.6"
        d="M29.5 22.1667C29.5 25.2043 31.9624 27.6667 35 27.6667C38.0376 27.6667 40.5 25.2043 40.5 22.1667C40.5 19.1292 38.0376 16.6667 35 16.6667C31.9624 16.6667 29.5 19.1292 29.5 22.1667Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconGroup;
