import styled, { css } from 'styled-components';
import { Base } from '~/components/layout';

const ProcessInner = styled(Base)`
  ${() => css`
    position: relative;
    padding-bottom: 100%;
  `}
`;

export default ProcessInner;
