/* eslint-disable max-len */
import React from 'react';

const IconFileDone = (props: React.ComponentProps<'svg'>) => {
  return (
    <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7143 4H27.4728C28.1822 4 28.8686 4.25137 29.4102 4.70948L38.9374 12.7679C39.6113 13.3379 40 14.1758 40 15.0584V40.1667C40 43.7478 39.9591 44 36.2857 44H11.7143C8.0409 44 8 43.7478 8 40.1667V7.83333C8 4.25225 8.0409 4 11.7143 4ZM21.75 31.5C22.2292 31.5 22.7083 31.3083 23.0917 30.925L30.7583 23.2583C31.525 22.4917 31.525 21.3417 30.7583 20.575C29.9917 19.8083 28.8417 19.8083 28.075 20.575L21.75 26.9L19.2583 24.4083C18.5875 23.6417 17.3417 23.6417 16.575 24.4083C15.8083 25.175 15.8083 26.325 16.575 27.0917L20.4083 30.925C20.7917 31.3083 21.2708 31.5 21.75 31.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.75 31.5C21.2708 31.5 20.7917 31.3083 20.4083 30.925L16.575 27.0917C15.8083 26.325 15.8083 25.175 16.575 24.4083C17.3417 23.6417 18.5875 23.6417 19.2583 24.4083L21.75 26.9L28.075 20.575C28.8417 19.8083 29.9917 19.8083 30.7583 20.575C31.525 21.3417 31.525 22.4917 30.7583 23.2583L23.0917 30.925C22.7083 31.3083 22.2292 31.5 21.75 31.5Z"
        fill="white"
      />
    </svg>
  );
};

export default IconFileDone;
